<template>
	<div>
		<div class="match-title">
			<i class="fa fa-circle"></i>
			{{title}}
		</div>
	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui'
	import {
		handleStreamlineDetails
	} from '@index/api/joinmatch/matchinfo'
	
	export default {
		name: 'matchtitle',
		components: {

		},
		data() {
			return {
				havAuth: false,
				title: '',
				matchId: sessionStorage.getItem('currMatchId'),
				
			};
		},
		watch: {

		},
		created() {
			this.matchdetails();
			
		},
		mounted() {
			this.intiTitle();
		},
		destroyed() {

		},
		methods: {
			intiTitle() {
				var temp = sessionStorage.getItem('domainInfo');
				this.domainInfo = temp ? JSON.parse(temp) : {
					schemeName: '我是记分长'
				};
				if (this.domainInfo) {
					document.title = this.domainInfo.schemeName;
				}
			},
			matchdetails() { //比赛详情
				handleStreamlineDetails(this.matchId).then(res => {
					if (res.status == 200) {
						this.title = res.data.matchName;
						var user = JSON.parse(sessionStorage.getItem('user'));
						this.havAuth = (user.id == res.data.createUserId);
						this.$emit('initAuth', this.havAuth);
					} else {
						Message({
							type: 'error',
							message: res.msg,
							uration: 5000
						});
					}
				});
			},
			

		}
	}
</script>

<style lang="scss" scoped>
	.match-title {
		height: 2.3rem;
		line-height: 2.3rem;
		font-size: 1.05rem !important;
		background: white;
		border-bottom: solid white 4px;
		// box-shadow: ghostwhite 10px 10px 30px 5px; //边框阴影;
		padding-left: 20px;

		i {
			color: #F32C6C;
			margin-right: 15px;
			clear: both;

		}

	}
</style>